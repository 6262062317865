import React from "react"
import classNames from "classnames"
import {graphql} from "gatsby"
import Helmet from "react-helmet"
import MainLayout from "Layout/layout"
import Img from "gatsby-image"
import SliderBasic from "Accessories/Slider/SliderBasic/SliderBasic"
import BrandGraphics from "Accessories/BrandGraphics/BrandGraphics"
import config from "../../../data/SiteConfig"
import "./event.scss"

const EventTemplate = ({
  data,
  location
}) => {

  const { page, optionsPage } = data
  const gI = optionsPage.edges[0].node.global_data.generic_images
  const eventLocation = page.acf.event.event_venue && page.acf.event.event_venue || ""

  const eventDateInfoStartDate = page.acf.event.event_date_info.event_date_info_start_date ? page.acf.event.event_date_info.event_date_info_start_date.split(" ") : false
  const eventDateInfoStartTime = page.acf.event.event_date_info.event_date_info_start_time ? page.acf.event.event_date_info.event_date_info_start_time.split(" ") : false
  const eventDateInfoEndDate = page.acf.event.event_date_info.event_date_info_end_date ? page.acf.event.event_date_info.event_date_info_end_date.split(" ") : false
  const eventDateInfoEndTime = page.acf.event.event_date_info.event_date_info_end_time ? page.acf.event.event_date_info.event_date_info_end_time.split(" ") : false

  const handleDateFormatting = (year, month, day, time, period) => {
    let dateFormatted = ""
    const yearFormatted = year
    let monthFormatted = ""
    const dayFormatted = day.replace(",", "").length > 1 ? day.replace(",", "") : `0${day.replace(",", "")}`

    switch (month) {
      case "January":
        monthFormatted = "01"
        break

      case "February":
        monthFormatted = "02"
        break

      case "March":
        monthFormatted = "03"
        break

      case "April":
        monthFormatted = "04"
        break

      case "May":
        monthFormatted = "05"
        break

      case "June":
        monthFormatted = "06"
        break

      case "July":
        monthFormatted = "07"
        break

      case "August":
        monthFormatted = "08"
        break

      case "September":
        monthFormatted = "09"
        break

      case "October":
        monthFormatted = "10"
        break

      case "November":
        monthFormatted = "11"
        break

      case "December":
        monthFormatted = "12"
        break

      default:
        break
    }

    // time
    if(time && period) {
      const isPM = period === "pm"
      const timeFormatted = isPM ? parseFloat(time.split(":")[0]) + 12 : parseFloat(time.split(":")[0])

      dateFormatted = `${yearFormatted + monthFormatted + dayFormatted}T${timeFormatted}${time.split(":")[1]}00Z`
    } else {
      dateFormatted = `${yearFormatted + monthFormatted + dayFormatted}`
    }

    return dateFormatted
  }

  const eventDateStartFormatted = eventDateInfoStartDate ? handleDateFormatting(eventDateInfoStartDate[2], eventDateInfoStartDate[0], eventDateInfoStartDate[1], eventDateInfoStartTime ? eventDateInfoStartTime[0] : false, eventDateInfoStartTime ? eventDateInfoStartTime[1] : false) : false
  const eventDateEndFormatted = eventDateInfoEndDate ? handleDateFormatting(eventDateInfoEndDate[2], eventDateInfoEndDate[0], eventDateInfoEndDate[1], eventDateInfoEndTime ? eventDateInfoEndTime[0] : false, eventDateInfoEndTime ? eventDateInfoEndTime[1] : false) : false

  function getRandomIntInclusive(min, max) {
    return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1)) + Math.ceil(min)
  }

  const swiperSetting = {
    slidesPerView: 1,
    grabCursor: false,
    lazy: true,
    breakpointsInverse: true,
    breakpoints: {
      1024: {
        allowTouchMove: false,
      }
    }
  }

  return (
    <MainLayout location={location}>
      <Helmet
        title={`${page.title} | ${config.siteTitle}`}
      />
      <div className="container-fluid section-event-content section pt-2 pt-lg-5">
        <div className="container">
          <div className="row event-details-row">
            <div className="event-details col-12 col-lg-3 order-2 order-lg-1 mt-2 mt-lg-4">
              {page.acf.event.event_facts &&
                <div className="event-facts mb-1" dangerouslySetInnerHTML={{ __html: page.acf.event.event_facts }} />
              }
              <div className="calendar">
                <span className="bracket-text">Calendar</span>
                <ul className="text-sm">
                  <li>
                    <a
                      href={`https://calendar.google.com/calendar/r/eventedit?text=${page.title}&details=${page.acf.event.event_subtitle ? page.acf.event.event_subtitle : ""}&location=${eventLocation}&dates=${(eventDateStartFormatted && eventDateStartFormatted) || undefined}/${(eventDateEndFormatted && eventDateEndFormatted) || (eventDateStartFormatted && eventDateStartFormatted) || undefined}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      + Google
                    </a>
                  </li>
                </ul>
              </div>
              {page.acf.event.speaker_facts &&
                <div className="speaker-facts mt-4" dangerouslySetInnerHTML={{ __html: page.acf.event.speaker_facts }} />
              }
            </div>
            <div className="event-content col-12 col-lg-7 order-1 order-lg-2">
              <span className="bracket-text">
                {page.categories_names.filter(item => item.taxonomy === "types").length && page.categories_names.filter(item => item.taxonomy === "types")[0].name}
              </span>
              <h1 className="event-title">{page.title}</h1>
              {
                page.acf.event.event_subtitle && <h2 className="event-subtitle text-md">{page.acf.event.event_subtitle}</h2>
              }
              <div className="mt-1 mb-2 event-description-container" dangerouslySetInnerHTML={{ __html: page.content }} />
              { page.acf.event.event_images && page.acf.event.event_images.length > 0 && (
                <SliderBasic
                  settings={swiperSetting}
                  classes=""
                >
                  {page.acf.event.event_images.map(
                    (eventImage) =>
                      eventImage.event_image && eventImage.event_image.localFile && (
                        <div
                          className="event-slide"
                          key={eventImage.event_image.localFile.childImageSharp.fluid.originalName}
                        >
                          {
                            eventImage.event_image.localFile.childImageSharp.fluid && (
                              <Img
                                fluid={eventImage.event_image.localFile.childImageSharp.fluid}
                              />
                            )
                          }
                          {
                            eventImage.event_image_caption && <span className="image-caption d-inline-block text-sm">{eventImage.event_image_caption}</span>
                          }
                        </div>
                      )
                  )}
                </SliderBasic>
                )
              }
            </div>
          </div>
          <div className="row pattern-container pt-3 d-none d-md-block">
            <div className={classNames(`col-md-${getRandomIntInclusive(2, 3)} col-lg-${getRandomIntInclusive(1, 2)} col-xl-1 offset-md-${getRandomIntInclusive(3, 4)}`)}>
              <BrandGraphics graphicType="pill" genericImages={gI} spacingTop={(getRandomIntInclusive(0, 8))} />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export const pageQuery = graphql`
  query eventSingleQuery($id: String!) {
    page: wordpressWpEventPosts(id: { eq: $id }) {
      id
      slug
      title
      content
      excerpt
      locations
      years
      types
      categories_names {
        name
        taxonomy
      }
      acf {
        event {
          event_subtitle
          event_facts
          speaker_facts
          event_venue
          event_date_info {
            event_date_info_end_date
            event_date_info_end_time
            event_date_info_start_date
            event_date_info_start_time
          }
          event_images {
            event_image_caption
            event_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1646) {
                    ...GatsbyImageSharpFluid_noBase64
                    originalName
                  }
                }
              }
            }
          }
        }
      }
    }
    optionsPage: allWordpressAcfOptions {
      edges {
        node {
          global_data {
            generic_images {
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default EventTemplate
